import React from 'react';

const UxUiWireframing = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.0907 37.3803H4.26417C3.39588 37.3727 2.56568 37.0223 1.95437 36.4057C1.34306 35.789 1.00009 34.9559 1.00013 34.0876V9.2927C0.996344 8.86166 1.07799 8.43414 1.24032 8.03483C1.40266 7.63551 1.64251 7.27217 1.94598 6.96604C2.24945 6.65991 2.61051 6.41694 3.00839 6.25112C3.40628 6.0853 3.83311 5.99998 4.26417 6H44.0907C44.964 6 45.8015 6.3468 46.4189 6.96429C47.0364 7.58178 47.3833 8.41944 47.3833 9.2927V34.0876C47.3758 34.9585 47.0265 35.7917 46.4106 36.4076C45.7948 37.0235 44.9617 37.3728 44.0907 37.3803ZM4.26417 7.43158C3.77556 7.4391 3.30947 7.63839 2.96662 7.9866C2.62378 8.3348 2.43165 8.80404 2.4317 9.2927V34.0876C2.43165 34.5762 2.62378 35.0453 2.96662 35.3935C3.30947 35.7417 3.77556 35.9412 4.26417 35.9487H44.0907C44.5843 35.9487 45.0576 35.7525 45.4066 35.4035C45.7556 35.0545 45.9517 34.5812 45.9517 34.0876V9.2927C45.9517 8.79912 45.7556 8.32565 45.4066 7.97663C45.0576 7.62762 44.5843 7.43158 44.0907 7.43158H4.26417Z"
      fill="#475467"
    />
    <path
      d="M31.8068 42.1347H16.5461C16.3563 42.1347 16.1743 42.0592 16.04 41.925C15.9058 41.7908 15.8303 41.6088 15.8303 41.4189C15.8303 41.2291 15.9058 41.0471 16.04 40.9128C16.1743 40.7786 16.3563 40.7031 16.5461 40.7031H31.8068C31.9966 40.7031 32.1786 40.7786 32.3129 40.9128C32.4471 41.0471 32.5226 41.2291 32.5226 41.4189C32.5226 41.6088 32.4471 41.7908 32.3129 41.925C32.1786 42.0592 31.9966 42.1347 31.8068 42.1347Z"
      fill="#475467"
    />
    <path
      d="M23.6478 23.9203C23.4566 23.9221 23.2721 23.8504 23.1324 23.7199L22.388 22.9754C22.2632 22.8384 22.1941 22.6597 22.1941 22.4744C22.1941 22.2891 22.2632 22.1104 22.388 21.9734C22.4548 21.9041 22.5348 21.849 22.6234 21.8114C22.7119 21.7737 22.8071 21.7542 22.9033 21.7542C22.9995 21.7542 23.0948 21.7737 23.1834 21.8114C23.2719 21.849 23.352 21.9041 23.4188 21.9734L23.6478 22.2023L24.9648 20.8854C25.1026 20.782 25.2732 20.7318 25.445 20.744C25.6169 20.7562 25.7786 20.8299 25.9004 20.9518C26.0223 21.0736 26.0961 21.2353 26.1083 21.4072C26.1205 21.579 26.0703 21.7496 25.9669 21.8874L24.1631 23.7199C24.0234 23.8504 23.8389 23.9221 23.6478 23.9203Z"
      fill="#475467"
    />
    <path
      d="M27.2539 26.0171H21.0981C20.9083 26.0171 20.7262 25.9416 20.5919 25.8074C20.4577 25.6731 20.3823 25.4911 20.3823 25.3013V19.2314C20.3823 19.0416 20.4577 18.8596 20.5919 18.7253C20.7262 18.5911 20.9083 18.5156 21.0981 18.5156H27.2539C27.4437 18.5156 27.6257 18.5911 27.76 18.7253C27.8942 18.8596 27.9697 19.0416 27.9697 19.2314V25.4158C27.9422 25.5851 27.8548 25.7391 27.7234 25.8495C27.5921 25.9599 27.4255 26.0193 27.2539 26.0171ZM21.8139 24.5855H26.5381V19.8327H21.8139V24.5855Z"
      fill="#475467"
    />
    <path
      d="M16.5449 26.0171H10.3891C10.1993 26.0171 10.0173 25.9416 9.88304 25.8074C9.74881 25.6731 9.67334 25.4911 9.67334 25.3013V19.2314C9.67334 19.0416 9.74881 18.8596 9.88304 18.7253C10.0173 18.5911 10.1993 18.5156 10.3891 18.5156H16.5449C16.7325 18.5227 16.9105 18.6005 17.0432 18.7332C17.1759 18.8659 17.2536 19.0438 17.2607 19.2314V25.4158C17.228 25.5827 17.1392 25.7335 17.009 25.8429C16.8787 25.9522 16.7149 26.0137 16.5449 26.0171ZM11.1049 24.5855H15.8291V19.8327H11.1049V24.5855Z"
      fill="#475467"
    />
    <path
      d="M37.9907 26.0171H31.8349C31.6451 26.0171 31.463 25.9416 31.3288 25.8074C31.1945 25.6731 31.1191 25.4911 31.1191 25.3013V19.2314C31.1191 19.0416 31.1945 18.8596 31.3288 18.7253C31.463 18.5911 31.6451 18.5156 31.8349 18.5156H37.9907C38.1805 18.5156 38.3626 18.5911 38.4968 18.7253C38.631 18.8596 38.7065 19.0416 38.7065 19.2314V25.4158C38.6791 25.5851 38.5916 25.7391 38.4603 25.8495C38.3289 25.9599 38.1623 26.0193 37.9907 26.0171ZM32.5507 24.5855H37.2749V19.8327H32.5507V24.5855Z"
      fill="#475467"
    />
  </svg>
);

export default UxUiWireframing;
